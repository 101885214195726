// React Basic and Bootstrap
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Button,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CountUp from "react-countup";
//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";

// Import Images
import seoSVG from "../../assets/images/illustrator/SEO_SVG.svg";
import mobileNotificationSVG from "../../assets/images/illustrator/Mobile_notification_SVG.svg";

const HowItWorks = () => {
  const [contactVisible, setContactVisible] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setContactVisible(true);
  };

  return (
    <React.Fragment>
      <section className="pt-5 pb-5 border-bottom">
        {/* <Container>
       
          <SectionTitle
            title="How It Works?"
            desc="that can provide everything you need to generate awareness, drive traffic, connect."
          />

          <Row className="align-items-center">
            <Col lg="5" md="6" className="mt-4 pt-2">
              <img src={seoSVG} alt="" />
            </Col>

            <Col lg="7" md="6" className="mt-4 pt-2">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-4">
                  Change the way you build websites
                </h4>
                <p className="text-muted">
                  You can combine all the IAastha templates into a single one,
                  you can take a component from the Application theme and use it
                  in the Website.
                </p>
                <ul className="list-unstyled text-muted">
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Our Talented & Experienced Marketing Agency
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Create your own skin to match your brand
                  </li>
                </ul>
                <Link to="/page-services" className="mt-3 h6 text-primary">
                  Find Out More{" "}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container> */}

        {/* <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col
              lg="7"
              md={{ size: 6, order: 1 }}
              xs={{ order: 2 }}
              className="mt-4 mt-sm-0 pt-2 pt-sm-0"
            >
              <div className="section-title">
                <h4 className="title mb-4">Speed up your development <br /> with <span className="text-primary">IAastha.</span></h4>
                <p className="text-muted">Using IAastha to build your site means never worrying about designing another page or cross-browser compatibility. Our ever-growing library of components and pre-designed layouts will make your life easier.</p>
                <ul className="list-unstyled text-muted">
                  <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
                  <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
                  <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
                </ul>
                <Link to="/page-contact-one" className="mt-3 h6 text-primary">
                  Find Out More <i className="uil uil-angle-right-b align-middle"></i>
                </Link>
              </div>
            </Col>

            <Col lg={5} md={6} className="order-1 order-md-2">
              <Card className="rounded border-0 shadow ms-lg-5">
                <CardBody>
                  <img src={mobileNotificationSVG} alt="" />

                  <div className="content mt-4 pt-2">
                    <Alert
                      color="info"
                      isOpen={contactVisible}
                      toggle={() => {
                        setContactVisible(!contactVisible);
                      }}
                    >
                      Download Successfully.
                    </Alert>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="12">
                          <div className="mb-3">
                            <Label className="form-label">
                              Name : <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </div>
                            <Input
                              type="text"
                              className="form-control ps-5"
                              placeholder="Name"
                              name="name"
                              required=""
                            />
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="mb-3">
                            <Label className="form-label">
                              Email : <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </div>
                            <Input
                              type="email"
                              className="form-control ps-5"
                              placeholder="Email"
                              name="email"
                              required
                            />
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="mb-3">
                            <Label className="form-label">
                              Password :{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <FeatherIcon
                                icon="key"
                                className="fea icon-sm icons"
                              />
                            </div>
                            <Input
                              type="password"
                              className="form-control ps-5"
                              placeholder="Password"
                              required
                            />
                          </div>
                        </Col>
                        <Col lg="12" className="mt-2 mb-0">
                          <Button
                            color="primary"
                            type="submit"
                            className="w-100"
                          >
                            Download
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container> */}
        <Container className="">
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="section-title">
                <h4 className="title mb-4">Client Achievements</h4>
                <p className="text-muted para-desc">
                  Explore the measurable success of our client collaborations.
                  Witness how our cutting-edge solution translate into
                  real-world results.
                </p>
                <ul className="list-unstyled text-muted">
                  <li
                    // className="mb-1"
                    style={{ display: "flex", alignItems: "baseline" }}
                  >
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    <p>Revenue Growth: Direct impact on client earnings.</p>
                  </li>
                  <li
                    // className="mb-1"
                    style={{ display: "flex", alignItems: "baseline" }}
                  >
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    <p>
                      Efficiency Boost: Streamlined operations, reduced costs.
                    </p>
                  </li>
                  <li
                    // className="mb-1"
                    style={{ display: "flex", alignItems: "baseline" }}
                  >
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    <p> Market Expansion: Broader reach, new demographics.</p>
                  </li>
                  <li
                    // className="mb-1"
                    style={{ display: "flex", alignItems: "baseline" }}
                  >
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    <p>
                      {" "}
                      Innovation Implementation: Deploying latest tech
                      advancements.
                    </p>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg="6">
              <Row className="ms-lg-5" id="counter">
                <Col md={6} xs={12}>
                  <Row>
                    <Col xs={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                      <Card className="counter-box border-0 bg-light shadow text-center rounded">
                        <CardBody className="py-5">
                          <h2 className="mb-0">
                            $
                            <span className="counter-value">
                              <CountUp start={10} end={10} />
                            </span>
                            M
                          </h2>
                          <h5 className="counter-head mb-0 title-dark">
                            Raised
                          </h5>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xs={12} className="mt-4 pt-2">
                      <Card className="card counter-box border-0 bg-primary bg-gradient shadow text-center rounded">
                        <CardBody className="py-5">
                          <h2 className="text-light title-dark mb-0">
                            <span className="counter-value">
                              <CountUp start={5} end={5} />
                            </span>
                            +
                          </h2>
                          <h5 className="counter-head mb-0 title-dark text-light">
                            Grants
                          </h5>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col md={6} xs={12}>
                  <Row className="pt-lg-4 mt-lg-4">
                    <Col xs={12} className="mt-4 pt-2">
                      <Card className="counter-box border-0 bg-success shadow text-center rounded">
                        <CardBody className="py-5">
                          <h2 className="text-light title-dark mb-0">
                            $
                            <span className="counter-value">
                              <CountUp start={300} end={300} />
                            </span>
                            K +
                          </h2>
                          <h5 className="counter-head mb-0 title-dark text-light">
                            MRR
                          </h5>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xs={12} className="mt-4 pt-2">
                      <Card className="counter-box border-0 bg-light shadow text-center rounded">
                        <CardBody className="py-5">
                          <h2 className="mb-0">
                            <span className="counter-value">
                              <CountUp start={98} end={98} />
                            </span>
                            %
                          </h2>
                          <h5 className="counter-head mb-0">
                            Project Complete
                          </h5>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default HowItWorks;
