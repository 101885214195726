/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef, react/no-unescaped-entities */
/*eslint-disable-next-line no-undef */

import { getFirestore } from "firebase/firestore";
// import firebase from 'firebase/app';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcATH4AIq_4nJ5H9r2e3csP3tF1fIfygo",
  authDomain: "iaastha.firebaseapp.com",
  databaseURL: "https://iaastha.firebaseio.com",
  projectId: "iaastha",
  storageBucket: "iaastha.appspot.com",
  messagingSenderId: "706355682766",
  appId: "1:706355682766:web:28ac78d2bae133e4029f9f",
  measurementId: "G-HB006MP2TQ",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// export const db = getFirestore(app);
export const db = firebase.firestore(app);
export const firestore = firebase.firestore();
