// React Basic and Bootstrap
import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Accordion,
  AccordionItem,
  Collapse,
  UncontrolledCollapse,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import SectionTitle from "../../components/Shared/SectionTitle";
// import engDev from "../../assets/images/work/eng-dev1.jpeg";
import engDev from "../../assets/images/modern01.jpg";
import process from "../../assets/images/work/process.jpeg";
import process1 from "../../assets/images/work/process1.jpeg";

const Faq = () => {
  return (
    <React.Fragment>
      <section className="section bg-light">
        {/* <Container>
          <Row>
            <Col md="6" xs="12">
              <div className="d-flex">
                <i>
                  <FeatherIcon
                    icon="help-circle"
                    className="fea icon-ex-md text-primary me-2 mt-1"
                  />
                </i>
                <div className="flex-1">
                  <h5 className="mt-0">
                    How our <span className="text-primary">Landrick</span> work
                    ?
                  </h5>
                  <p className="answer text-muted mb-0">
                    Due to its widespread use as filler text for layouts,
                    non-readability is of great importance: human perception is
                    tuned to recognize certain patterns and repetitions in
                    texts.
                  </p>
                </div>
              </div>
            </Col>

            <Col md="6" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="d-flex">
                <i>
                  <FeatherIcon
                    icon="help-circle"
                    className="fea icon-ex-md text-primary me-2 mt-1"
                  />
                </i>
                <div className="flex-1">
                  <h5 className="mt-0">
                    {" "}
                    What is the main process open account ?
                  </h5>
                  <p className="answer text-muted mb-0">
                    If the distribution of letters and 'words' is random, the
                    reader will not be distracted from making a neutral
                    judgement on the visual impact
                  </p>
                </div>
              </div>
            </Col>

            <Col md="6" xs="12" className="mt-4 pt-2">
              <div className="d-flex">
                <i>
                  <FeatherIcon
                    icon="help-circle"
                    className="fea icon-ex-md text-primary me-2 mt-1"
                  />
                </i>
                <div className="flex-1">
                  <h5 className="mt-0"> How to make unlimited data entry ?</h5>
                  <p className="answer text-muted mb-0">
                    Furthermore, it is advantageous when the dummy text is
                    relatively realistic so that the layout impression of the
                    final publication is not compromised.
                  </p>
                </div>
              </div>
            </Col>

            <Col md="6" xs="12" className="mt-4 pt-2">
              <div className="d-flex">
                <i>
                  <FeatherIcon
                    icon="help-circle"
                    className="fea icon-ex-md text-primary me-2 mt-1"
                  />
                </i>
                <div className="flex-1">
                  <h5 className="mt-0">
                    {" "}
                    Is <span className="text-primary">Landrick</span> safer to
                    use with my account ?
                  </h5>
                  <p className="answer text-muted mb-0">
                    The most well-known dummy text is the 'Lorem Ipsum', which
                    is said to have originated in the 16th century. Lorem Ipsum
                    is composed in a pseudo-Latin language which more or less
                    corresponds to 'proper' Latin.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
            <Col xs={12} className="text-center">
              <div className="section-title">
                <h4 className="title mb-4">Have Question ? Get in touch!</h4>
                <p className="text-muted para-desc mx-auto">
                  Start working with{" "}
                  <span className="text-primary fw-bold">Landrick</span> that
                  can provide everything you need to generate awareness, drive
                  traffic, connect.
                </p>
                <Link to="/page-contact-one" className="btn btn-primary mt-4">
                  {" "}
                  <i className="uil uil-phone"></i> Contact us
                </Link>
              </div>
            </Col>
          </Row>
        </Container> */}
        <Container>
          <SectionTitle
            title="Excellence Across Domains"
            desc="A team that's constantly evolving and at the forefront of technology, ready to deliver transformaative solutions that drive your success."
          />

          <Row className="align-items-center">
            <Col lg={7} md={6} className="mt-4 pt-2">
              <img src={engDev} alt="" className="img-fluid" />
            </Col>

            <Col lg={5} md={6} className="mt-4 pt-2">
              <div className="section-title ms-lg-5">
                <h5 className=" mb-4">
                  Our commitment to excellence starts with rigorous training and
                  upskilling in three key domains.
                </h5>
                {/* <p className="text-muted">
                  You can combine all the IAastha templates into a single one,
                  you can take a component from the Application theme and use it
                  in the Website.
                </p> */}
                <ul className="list-unstyled text-muted">
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    AL/ML
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Data Engineering
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Web Development
                  </li>
                </ul>
                {/* <Link to="/page-services" className="mt-3 h6 text-primary">
                  Find Out More{" "}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mt-5">
        <Container>
          {/* <SectionTitle
            title="Our Onboarding Process"
            desc=""
          /> */}
          <div className="text-center">
            <h3>Our Onboarding Process</h3>
          </div>

          <Row className="align-items-center pb-3 mt-3">
            <Col lg={12} md={12} className="">
              <img
                src={process}
                alt=""
                className="img-fluid d-none d-md-block"
              />
              <img
                src={process1}
                alt=""
                className="img-fluid d-block d-md-none"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mb-5">
        <Container>
          <Row>
            <Col className="">
              <div className="component-wrapper">
                <div className="">
                  <div>
                    <div className="accordion" id="buyingquestion">
                      <div className="accordion-item rounded">
                        <h2 className="accordion-header">
                          <button
                            id="headingOne"
                            className="accordion-button border-0 bg-light"
                            type="button"
                          >
                            We provide full transparency through reports
                          </button>
                        </h2>
                        <UncontrolledCollapse
                          defaultOpen
                          toggler="#headingOne"
                          className="border-0"
                        >
                          <div className="accordion-body text-muted">
                            <p>
                              Wheather an indivitual or a team working with you,
                              we provide complete transparency through daily and
                              weekly reports. Example
                            </p>
                            <a
                              href={require("../../assets/pdfs/weekly_report.pdf")}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="pt-6"
                            >
                              Weekly Report
                            </a>
                            &nbsp;&
                            <a
                              href={require("../../assets/pdfs/daily_report.pdf")}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="pt-6"
                            >
                              &nbsp;Daily Report
                            </a>
                          </div>
                        </UncontrolledCollapse>
                      </div>

                      {/* <div className="accordion-item rounded mt-2">
                        <h2 className="accordion-header">
                          <button
                            id="headingTwo"
                            className="accordion-button border-0 bg-light collapsed"
                            type="button"
                          >
                            Do I need a designer to use Landrick ?
                          </button>
                        </h2>
                        <UncontrolledCollapse
                          toggler="#headingTwo"
                          className="border-0"
                        >
                          <div className="accordion-body text-muted">
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form.
                          </div>
                        </UncontrolledCollapse>
                      </div>

                      <div className="accordion-item rounded mt-2">
                        <h2 className="accordion-header">
                          <button
                            id="headingThree"
                            className="accordion-button border-0 bg-light collapsed"
                            type="button"
                          >
                            What do I need to do to start selling ?
                          </button>
                        </h2>
                        <UncontrolledCollapse
                          toggler="#headingThree"
                          className="border-0"
                        >
                          <div className="accordion-body text-muted">
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form.
                          </div>
                        </UncontrolledCollapse>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Faq;
